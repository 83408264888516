<template>
  <div id="links">
    <b-container>
      <b-row class="content">
        <b-col>
          <b-img
            class="logo"
            src="https://assets.asiantour.com/asian-tour/2020/05/titleist-logo-png.png"
          ></b-img>
        </b-col>
      </b-row>
      <b-row class="content">
        <b-col>
          <span v-html="this.$route.query.content.rendered"></span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "links"
};
</script>

<style scoped>
#links {
  background-color: #f1f1f1;
}
.logo {
  width: 120px;
  float: right;
}
.content {
  padding-top: 10%;
}
</style>
